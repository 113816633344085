import React from "react";
import {graphql, Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";
import styled from 'styled-components';
import Layout from "../components/layout"
import Seo from "../components/seo"
import LangSelector from "../components/LangSelector";
import { ABOUT_PROJECT, LOCATION } from "../routes";
import { Pagination, Autoplay, Navigation, EffectFade  } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import sliderSrc01 from '../images/img-slider-01.jpg';
// import sliderSrc02 from '../images/img-slider-02.jpg';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 2;
  margin-top: 1rem;

  a {
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    background-color: #ff940a;
    padding: 0.875rem 1.5rem;
    box-shadow: -1px 3px 7px rgba(0, 0, 0, 0.72);
  }

  a:hover {
    color: #fff;
    text-decoration: none;
    background-color: #303030;
  }

  @media (min-width: 768px) {
    a {
      padding: 1rem 3rem;
    }
  }

`;

const SliderWrapper = styled.div`
  height: calc(100vh - 264px);
  position: relative;

  .swiper {
    height: 100%;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    background-color: ${({theme}) => theme.colors.white};
    width: 30px;
    height: 30px;
    border-radius: 0;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
  }

  .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    opacity: 1;
    width: 30px;
    height: 3px;
    border-radius: 0;
    background-color: rgba(218,218,218,0.7);
    margin-left: 6px;
    margin-right: 6px;

    &:hover {
      background-color: rgba(255,148,10,0.7);
    }
  }

  .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet-active {
    background-color: rgba(255,148,10,0.7);
  }

  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 100px;
  }

  @media (min-width: 576px) {
    min-height: calc(100vh - 213px);
  }

  @media (min-width: 768px) {
    /* height: calc(100vh - 52px); */

    .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
      width: 60px;
      height: 5px;
    }
  }

  @media (min-width: 992px) {
    min-height: calc(100vh - 92px);
  }

  @media (min-width: 1200px) {
    min-height: calc(100vh - 52px);
  }

  /* @media (min-width: 1400px) {
    height: calc(100vh - 52px);
  } */
`;

const Title = styled.p`
  font-family: 'Playfair Display', Georgia, sans-serif;
  font-weight: 700;
  color: #fefefe;
  text-align: center;
  font-size: 2rem;
  line-height: 2.5rem;
  max-width: 750px;
  text-shadow: -1px 3px 7px rgba(0, 0, 0, 0.72);
  z-index: 2;
  margin-bottom: 0.5rem;

  @media (min-width: 768px) {
    font-size: 4rem;
    line-height: 4.5rem;
  }
`;

const Text = styled.p`
  color: #fefefe;
  text-align: center;
  font-size: 1rem;
  line-height: 1.75rem;
  max-width: 750px;
  text-shadow: -1px 3px 7px rgba(0, 0, 0, 0.72);
  z-index: 2;
`;

const Item = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding: 1rem;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #303030;
    opacity: 0.3;
  }

  .slider-image {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  p {
    color: #fff;
  }
`;

const HomePage = () => {

  const { t } = useTranslation();

  return (
    <Layout showPageTitle>
      <Seo title={t('seo.index.title')} />
      <SliderWrapper>
        <LangSelector />
      <Swiper
          effect={"fade"}
          modules={[EffectFade, Navigation, Pagination, Autoplay]}
          loop
          pagination={{
            clickable: true,
            renderBullet: (index, className) => {
              return '<span class="' + className + '"></span>';
            }
          }}
          slidesPerView={1}
          speed={1000}
          autoplay={{
            delay: 8000
            }
          }
        >
          <SwiperSlide>
            <Item>
                <StaticImage src="../images/img-slider-01.jpg" className="slider-image" placeholder="blurred" alt={t('photo_alt')} />
                <Title>{t('slider.item_1.title')}</Title>
                <Text>{t('slider.item_1.subtitle')}</Text>
                <ButtonWrapper>
                  <Link to={LOCATION}>{t('slider.item_1.btn_label')}</Link>
                </ButtonWrapper>
            </Item>
          </SwiperSlide>
          <SwiperSlide>
            <Item>
              <StaticImage src="../images/img-slider-02.jpg" className="slider-image" placeholder="blurred" alt={t('photo_alt')} />
                <Title>{t('slider.item_2.title')}</Title>
                <Text>{t('slider.item_2.subtitle')}</Text>
                <ButtonWrapper>
                  <Link to={ABOUT_PROJECT}>{t('slider.item_2.btn_label')}</Link>
                </ButtonWrapper>
            </Item>
          </SwiperSlide>
        </Swiper>
      </SliderWrapper>
    </Layout>
  )
}

export default HomePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
